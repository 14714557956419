import React from "react"
import UnderConstruction from "../components/UnderConstruction"

const Payment = () => {
    return (
        <>
            <UnderConstruction />
        </>
    )
}

export default Payment
